@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;900&display=swap");

.login__heading {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #023047;
  margin: 0;
}

.login__check {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  margin: 24px 0px;
  margin-bottom: 13px;
  color: #023047;
}

.login__buttons {
  border: 1px solid #219ebc;
  border-radius: 10px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 22.3091px;
  color: #219ebc;
  background-color: white;
  cursor: pointer;
  padding: 10px 65px;
}

.login__label {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: #023047;
}

.login__input {
  background: #e0e0e0;
  border-radius: 10px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  margin-bottom: 24px;
  padding: 17px 29px;
  width: 90%;
  border: none;
  outline-offset: 0;
}

input:focus {
  outline: none !important;
}

.login__button {
  background: #219ebc;
  border-radius: 10px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 22.3091px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  padding: 15px 220px;
  width: 100%;
  border: none;
  cursor: pointer;
}

.login__button:hover {
  background: #1488a4;
}

.active {
  border-radius: 10px;
  border: 1px solid #219ebc;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 22.3091px;
  color: #ffffff;
  background-color: white;
  cursor: pointer;
  padding: 10px 65px;
  background: #219ebc;
}

.login__or {
  font-weight: 300;
  font-size: 20px;
  align-items: center;
  text-align: center;

  color: #023047;
}

.login__register {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  margin-left: 10px;
  cursor: pointer;
}

.flexCenteredContainer {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  justify-content: space-between;
  margin: auto;
}

.title {
  color: #023047;
}

.loginBoxStyle {
  zoom: "70%";
  background-color: "#ffffff !important";
  margin-left: "auto";
  margin-right: "auto";
  margin-top: "0";
  box-shadow: "0px 0px 50px rgba(0, 0, 0, 0.25)";
  width: "646px";
  display: "flex";
  flex-direction: "column";
  margin: "auto";
  border-radius: "10px";
  padding: "13px 33px";
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .animate-slide-down {
    animation: slideDown 0.5s ease-in-out;
  }
}
