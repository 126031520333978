@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;900&display=swap");
/* Navbar styling */

.navbar {
  display: flex;
  font-family: "Outfit", sans-serif;
  /* padding: 2rem 3rem; */
  /* border: 10px red solid; */
}

.navbar h1 {
  /* margin: 0rem 1rem 0rem 0rem; */
  cursor: pointer;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 800;
  font-size: 36.1832px;
  color: #023047;
}

.navbar__avatar {
  margin: auto 0rem auto auto;
  cursor: pointer;
  zoom: 130%;
}

.navbar p {
  margin: auto 2rem auto 2rem;
  cursor: pointer;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  color: #023047;
}

.navbar__active {
  font-weight: 500 !important;
  border-bottom: 2.5px solid #023047 !important;
  width: 56px !important;
}

.filters__search {
  background: #023047;
  border-radius: 8.81319px;
  color: white;
  padding: 10px 55px;
  font-family: "outfit";
  font-weight: 500;
  font-size: 21.3382px;
  margin-top: auto;
  /* align-items: flex-end; */
  margin-bottom: 0.5rem;
  /* height: auto; */
  cursor: pointer;
  /* margin-left: 3rem; */
}

.filters__search:hover {
  background-color: #012638;
}

.jobboard__filterheading {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 32.9167px;
  display: flex;
  color: #023047;
  margin-bottom: 25px;
  margin-top: 0;
}

.jobboard__search {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  color: #023047;
}

.search__fields {
  background: #d3d3d3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8.85714px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 15.2874px;
  color: #646464;
  padding: 5px 40px;
  margin: 7px;
  cursor: pointer;
}

.joblist__h {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 168.5%;
  color: #444444;
  flex: 1;
  margin-top: 0;
}

.jobboard__sortby {
  background: #e0e0e0;
  border-radius: 8.97092px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-size: 17.9418px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0;
  margin-bottom: auto;
  justify-content: center;
  padding: 5px 13px;
  margin-right: 5px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .navbar p {
    font-size: 20px;
  }
}
