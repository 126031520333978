@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;900&display=swap");
/* Navbar styling */

.navbar {
  display: flex;
  font-family: "Outfit", sans-serif;
  padding: 2rem 0rem;
  /* border: 10px red solid; */
}

.navbar h1 {
  margin: 0rem 1rem 0rem 0rem;
  cursor: pointer;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 800;
  font-size: 36.1832px;
  color: #023047;
}

.navbar__avatar {
  margin: auto 0rem auto auto;
  cursor: pointer;
  zoom: 130%;
}

.navbar p {
  margin: auto 2rem auto 2rem;
  cursor: pointer;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  color: #023047;
}

.navbar__active {
  font-weight: 500 !important;
  border-bottom: 2.5px solid #023047 !important;
  width: 56px !important;
}

/* Personal Info Section */

.personalInfo {
  font-family: "Outfit";
  border: 1px solid #fff;
  border-radius: 1rem;
  width: 110%;
  margin-bottom: 2rem;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.NewPersonalInfo {
  border: 1px solid #fff;
  /* border-radius: 1rem; */
  /* width: 110%; */
  /* margin-bottom: 2rem; */
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.personalInfo__avatar {
  margin: 0.5rem auto 0.3rem auto;
  cursor: pointer;
  zoom: 400%;
  outline: 2px solid #8743df;
  outline-offset: 2px;
}

.personalInfo h2,
h4 {
  text-align: center;
}

.personalInfo h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 500;
}

.personalInfo h4 {
  font-weight: 300;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 300;
}

.personalInfo__skills {
  display: flex;
  justify-content: space-between;
  padding: 3rem 2rem 1rem 2rem;
  border-bottom: 1px solid rgb(211, 211, 211);
}

.skill__section :first-child {
  border: 1px solid #e78123;
  display: inline;
  padding: 1rem 0.7rem;
  border-radius: 15px;
  margin: 1rem auto;
  background-color: #e78123;
  color: white;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.13);
}

.skill__section p {
  margin-top: 2.5rem;
}

.personalInfo__information {
  margin-left: 1.5rem;
  color: #3d3d3d;
}

.personalInfo__information h3 {
  font-weight: 500 !important;
  font-size: 18px !important;
}

/* Activities */
.activities {
  font-family: "outfit";
  border: 1px solid rgb(210, 210, 210);
  width: 110%;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.activities h3 {
  margin: 1.5rem 1rem;
  font-weight: 500;
  font-size: 18px;
  color: #3d3d3d;
}

.activities__details {
  display: flex;
  margin: 1rem 3rem 1rem 1rem;
  font-size: 1rem;
}

.activities__detailsticker {
  margin-bottom: auto;
}

.activities__detailInfo {
  margin: 1rem;
  margin-top: 0;
  font-weight: 500;
  font-size: 14px;
  color: #616161;
}
a,
a:hover,
a:active,
a:visited {
  color: white;
  text-decoration: none;
}
