@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .blueFatButton {
    @apply bg-primarycolor rounded md:mx-0 border-none font-bold text-white py-[18px] px-[37px] cursor-pointer;
  }
  .whiteButton {
    @apply px-3 py-1 text-primarycolor bg-white border hover:cursor-pointer border-primarycolor rounded border-solid;
  }
  .applyNowButton {
    @apply bg-primarycolor cursor-pointer max-w-fit text-white rounded-[5px] sm:leading-[27px]  text-sm font-bold py-2.5 px-5;
  }
  .rightUserIcons {
    @apply w-5 sm:w-6 sm:h-6 cursor-pointer;
  }

  .spacePadding {
    @apply px-0 sm:px-2 md:px-3 lg:px-5;
  }

  .smColsmdRow {
    @apply flex-col md:flex-row;
  }

  .homepageCard {
    @apply rounded-2xl cursor-pointer px-10 xl:px-[50px] pb-[41px]  flex flex-col my-auto gap-1  recruiterShadowContent;
  }
  .secondary-job-padding-x {
    @apply px-[15px] sm:px-[30px] md:px-[15px] lg:px-[30px];
  }

  .check-rounded-icon {
    @apply text-[#FF8A00] text-[22px] font-extrabold
  }

  .small-cards-div {
    @apply flex flex-col md:flex-row gap-[45px] lg:gap-[20px] space-y-0
  }

  .table-paper {
    @apply mb-2 p-3
  }
}

.master {
  margin: 0px;
  padding: 0px;
}
.center-align {
  max-width: 1440px;
  margin: auto;
}

.shadowComponent {
  box-shadow: 0px 0px 16px 0px #0054b51f;
}
.recruiterShadowContent {
  box-shadow: 0px 0px 10px 0px #00459514;
}

div.MuiTabs-flexContainer {
  /* background: linear-gradient(45deg, red 30%, #ff8e53 90%); */
  color: 'black';
  justify-content: space-between;
}
