@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;900&display=swap');

.candidateprofile {
    font-family: "outift" !important;
}

@media only screen and (min-width: 1440px) {
    /* .candidateprofile {
        padding-left: 4rem;
        padding-right: 4rem;
        /* border: #2BC155; */
    /* } */ 
}

@media only screen and (min-width: 1100px) {
    .basicInfo__name {
        margin-right: 1rem;
    }

    .basicInfo__name {
        padding-right: 1rem;
    }

    .basicInfo__skills {
        margin-left: 1rem;
    }

    .reactchart1 {
        margin-left: -5rem;
    }
}

@media only screen and (min-width: 1024) {
    .basicInfo__skills div {
        width: 100px;
    }
}

.candidateprofile__buttons {
    display: flex;
    padding: 0 1.5rem 1.5rem 0;
    justify-content: end;
}

.buttons__shortlist {
    border: 1px solid #2BC155;
    border-radius: 10px;
    font-weight: 500;
    font-size: 13.219px;
    line-height: 17px;
    text-align: center;
    color: #2BC155;
    padding: .6rem 1.2rem;
    cursor: pointer;
    margin: auto 0.5rem;
}

.buttons__reject {
    font-weight: 500;
    font-size: 13.219px;
    border: 1px solid #E63946;
    border-radius: 10px;
    text-align: center;
    color: #E63946;
    padding: .6rem 1.2rem;
    cursor: pointer;
    margin: auto 0.5rem;
}

.buttons__downloadcv {
    background: #ECB365;
    border: 1px solid #000000;
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    padding: .4rem .8rem;
    cursor: pointer;
    margin: auto 0.5rem;
}

/* Basic Info */

.basicInfo {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    margin: 0rem 1.5rem 1rem 1.5rem;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1.5rem;
    /* margin-top: 0; */
}

.basicInfo__name {
    margin-right: 1rem;
}

.basicInfo__name div {
    display: flex;
}

.basicInfo__name div img {
    width: 106.57px;
    height: 97px;
    border-radius: 10px;
}

.basicInfo__rating {
    margin-top: .5rem;
}

.basicInfo__icons {
    margin-top: 1rem;
}

.basicInfo__contactInfo {
    border-right: 2px solid #DCDCDC;
    padding-right: 3rem;
}

.contactInfo__heading {
    font-weight: 400 !important;
    font-size: 19.3228px !important;
    margin-top: 0;
}

.basicInfo__contactInfo p {
    font-weight: 300;
    font-size: 15.4583px;
    font-family: "outfit";
}

.basicInfo__skills {
    margin-left: 3rem;
    /* margin-right: 3rem; */
    /* background-color: #2BC155; */
    display: flex;
    flex-grow: 1;
}

.basicInfo__skills div {

    /* width: 90%; */
    /* height: auto; */
}

.basicInfo__skills div p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    margin: 0;
    margin-bottom: .5rem;
}

.languagesProjects {
    background-color: #FFFFFF;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    padding: 1rem 1.5rem;
    margin: 1.5rem;
}

/* Projects and work experience */

.projectsW {
    background: #FFFFFF;
    border: 1px solid #AAAAAA;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: 1.5rem;
    padding: 1rem 1.5rem;
    margin-right: 0.5rem;
}
.projectsW_Full {
    background: #FFFFFF;
    border: 1px solid #AAAAAA;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: 1.5rem;
    padding: 1rem 1.5rem;
    margin-right: 1.5rem;
}

.projectsW__heading {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    border-bottom: 2px solid #DCDCDC;
    padding-bottom: .5rem;
    margin: .5rem;
}

.projectsW__details {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    border-left: 1px dashed #000000;
    padding-left: 1.5rem;
}

.projectsW :last-child {
    border-left: none;
}

.details__brownBox {
    background-color: #ECB365;
    border: 1px solid #000000;
    border-radius: 10px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    /* max-width: 40%; */
    padding: 0.5rem;
    margin-bottom: 0;
}
.details__brownBox_Projects {
    background-color: #ECB365;
    border: 1px solid #000000;
    border-radius: 10px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    max-width: 40%;
    padding: 0.5rem;
    margin-bottom: 0;
}
.details__brownBox_Experience {
     background-color: #ECB365;
     border: 1px solid #000000;
     border-radius: 10px;
     font-family: 'Outfit';
     font-style: normal;
     font-weight: 500;
     font-size: 12px;
     text-align: center;
     cursor: pointer;
    /* white-space: nowrap; */
     max-width: 100%;
     padding: 0.5rem 1.5rem;
     margin-bottom: 0;
 }


.details__title {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.details__thinText {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: #545151;
}

.details__description {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    margin-top: 0.5rem;
    margin-bottom: 0;
}