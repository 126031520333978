@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;900&display=swap');

.applications {
    margin: 1rem 3.5rem;
    font-family: "outfit" !important;
    font-style: normal;
}

.applications__heading p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
}

.applications__all {
    background: #FFFFFF;
    border-radius: 0.826187px;
}

.applications__details {
    display: flex;
    justify-content: space-between;
    padding-top: 59px;
    padding-right: 14px;
}

.details__photo img {
    width: 154px;
    height: 144px;
    border-radius: 10px;
    margin-left: 14px;
}

.details__basicInfo h2 {
    margin: 0;
    font-weight: 600;
    font-size: 17.4922px;
    line-height: 22px;
    color: #8743DF;
}

.details__basicInfo p {
    font-weight: 400;
    font-size: 17.4922px;
    line-height: 22px;
}

.details__basicInfo :last-child {
    font-weight: 500;
    font-size: 19.3286px;
    line-height: 24px;
    color: #657AE6;
    cursor: pointer;
}

.details__languages h2 {
    margin: 0;
    font-weight: 600;
    font-size: 17.4922px;
    line-height: 22px;
    color: #8743DF;
}

.details__proffesional>div {
    margin-bottom: 1rem;
}

.details__proffesional div p {
    margin: 0;
    font-weight: 200;
    font-size: 14.8149px;
    line-height: 19px;
}

.details__proffesional div :first-child {
    margin: 0;
    font-weight: 600;
    font-size: 17.4922px;
    line-height: 22px;
}

.details__buttons>button {
    background-color: #1D3557;
    border-radius: 10px;
    font-weight: 500 !important;
    font-size: 13.219px !important;
    font-family: "outfit" !important;
    color: #FFFFFF !important;
    margin-top: 2rem;
    padding: 11px 40px !important;
}

.buttons__one {
    border: 1px solid #2BC155;
    border-radius: 10px;
    font-weight: 500;
    font-size: 13.219px;
    line-height: 17px;
    text-align: center;
    color: #2BC155;
    width: 100%;
    cursor: pointer;
    padding: 10px 13px;
    margin-right: 0.5rem;
}

.buttons__two {
    border: 1px solid #E63946;
    border-radius: 10px;
    font-weight: 500;
    font-size: 13.219px;
    line-height: 17px;
    text-align: center;
    color: #E63946;
    cursor: pointer;
    width: 100%;
    padding: 10px 13px;
}


.projects__details {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: center;
}