@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;900&display=swap');

.header {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header-banner {
    background-color: rgba(83, 82, 237, 0.5);
    /* height: 738px; */
}

.header__navbar {
    display: flex;
    align-items: center;
    margin: 0rem 3rem;
    /* margin-left: auto; */
}

.header__navbar_img {
    margin-top: 25px;
    margin-left: auto;
    height: 50px;
    width: 40px;
    display: none;
}

.header__navbarLogo {
    cursor: pointer;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 800;
    font-size: 36.1832px;
    color: #023047;
    /* flex: 1; */
}

.header__navbarButton {
    background-color: white;
    border-radius: 15.0995px;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    margin-right: 1rem;
    cursor: pointer;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 18.1194px;
    color: #023047;
    height: 50px;
}
header__navbarButton_mobile {
    display: none;
}

.header__heading {
    margin: 5.5rem 3rem;
    margin-bottom: 3rem;
    /* background-color: #252B42; */
}

.header__heading1 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 72.2188px;
    line-height: 94px;
    color: #023047;
    margin-bottom: 0;
}

.custom img {
    display: none;
}

.header__heading2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 74.2188px;
    line-height: 94px;
    color: #023047;
    margin-top: 0;
}

.header__search {
    display: flex;
    background-color: rgb(255, 255, 255);
    /* background-color: red; */
    margin: 2rem 0rem 0rem 3rem;
    /* padding:2rem; */
    /* width: 100%; */
    border-radius: 1rem;
    justify-content: space-between;
    margin-bottom: 0;
}
.searchBarFlex{
    display:flex;
    align-items: center;
    background-color: #97a8b0;
    flex-grow: 1;


}
.searchBarItems {
    display: flex;
    align-items: center;
    flex-grow: 1;
    /* justify-content: space-between; */
    /* background-color: #1A85C2; */
}

.data {
    width: 60%;
    margin-left: 5rem;
    padding: 0px;
    text-align: center;
    margin-left: auto;
    border: 1px solid white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    margin-right: auto;
    padding: 2rem 3rem;
    border-radius: 1rem;
    margin-top: -2rem;
    background-color: #fff;
}

.header__data {
    display: flex;
    justify-content: center;
}

.data_box {
    margin-left: 3rem;
    margin-right: 3rem;
}

.data__boxNumber {
    margin: 0;
    letter-spacing: 0.5rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
}

.data__boxTitle {
    margin: 0;
    text-align: center;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
}



.search__helper {
    display: flex;
    align-items: center;
    margin:0rem 0.9rem;
    /* background-color: #011823; */
}

.helper__text {
    margin-left: 0.5rem;
    border: none;
    outline-width: 0px;
    width: 70%;
}

::placeholder {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #2D2D2D;
}

.search__button {
    background: #023047;
    margin-right: 0.5rem;
    padding: 0.7rem 1.5rem 0.8rem 1.5rem;
    color: white;
    border-radius: 8.38778px;
    cursor: pointer;
}
.search__button:hover{
    background: #011823;
}


.search2 {
    display: none;
}

.header_jobtype {
    margin: 2rem 3.3rem;
    margin-top: 0.5rem;
}

.company-logos {
    margin-left: 3rem;
    margin-bottom: 2.5rem;
}

.company-logos img {
    padding-right: 2rem;
    opacity: 0.5;
}

@media only screen and (max-width:767px) {
    .header-banner {
        /* height: 380px; */
    }

    .header__navbar {
        display: flex;
        margin: 0rem 1.5rem;
    }

    .header__navbarLogo {
        font-size: 22px;
    }

    .header__navbar .header__navbarButton {
        display: none;
    }


    .header__navbar img {
        display: block;
        margin-top: 20px;
        margin-left: auto;
        height: 28px;
        width: 28px;
    }

    .header__heading {
        margin: 1rem 0;
        margin-bottom: 3rem;
    }


    .header__heading1,
    .header__heading2 {
        font-size: 25px;
        font-weight: 400;
        line-height: 31.5px;
        margin-left: 2rem;
    }

    .custom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .custom img {
        display: block;
        width: 150px;
        margin-top: 7px;
    }

    .radio-name {
        display: flex;
        margin-left: 10px;
    }

    .header_jobtype {
        margin: 0;
    }

    .cartoon-img {
        display: none;
    }

    .helper__text {
        margin-left: 0;

    }

    ::placeholder {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #2D2D2D;
    }

    .radio-part {
        display: flex;
    }

    .search1 {
        display: none;
    }

    .search2 {
        display: block;
    }

    .header__search {
        margin: 0 0.5rem;
        margin-top: 1rem;
        width: fit-content;
    }

    .company-logos {
        display: none;
    }

    .data {
        width: 60%;
    }

    .header__data {
        justify-content: space-between;
    }

    .data_box {
        margin-left: 0;
        margin-right: 0;
    }

    .data__boxNumber {
        margin: 0;
        letter-spacing: 0.1rem;
        font-weight: 400;
        font-size: 11.5482px;
        line-height: 15px;
        font-size: 14px;
    }

    .data__boxTitle {
        font-weight: 500;
        font-size: 12.4466px;
        line-height: 16px;
    }
}

@media only screen and (min-width:768px) and (max-width:970px) {
    .header-banner {
        /* height: 570px; */
    }

    .header__navbar {
        display: flex;
        margin: 0rem 1.5rem;
    }

    .header__navbarLogo {
        font-size: 22px;
    }

    .header__navbar .header__navbarButton {
        display: none;
    }
    .header__navbar .header__navbarButtonMobile{
        background-color: white;
        border-radius: 15.0995px;
        /* margin-left: auto; */
        display: flex;
        align-items: center;
        padding: 0px 20px;
        margin-right: 1rem;
        cursor: pointer;
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 500;
        font-size: 18.1194px;
        color: #023047;
        height: 50px;
    }


    .header__navbar img {
        display: block;
        margin-top: 20px;
        margin-left: auto;
        height: 28px;
        width: 28px;
    }

    .header__heading {
        margin: 1rem 0;
        margin-bottom: 3rem;
    }


    .header__heading1,
    .header__heading2 {
        font-size: 50px;
        font-weight: 400;
        line-height: 60px;
        margin-left: 20px;
    }

    .custom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5rem;
    }

    .custom img {
        display: block;
        width: 350px;
        margin-top: 7px;
    }

    .radio-name {
        display: flex;
        margin-left: 10px;
    }

    .header_jobtype {
        margin: 0;
    }

    .cartoon-img {
        display: none;
    }

    .helper__text {
        margin-left: 0;

    }

    ::placeholder {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #2D2D2D;
    }

    .radio-part {
        display: flex;
    }

    .search1 {
        display: none;
    }

    .search2 {
        display: block;
    }

    .header__search {
        margin: 0 0.5rem;
        margin-top: 3rem;
        width: fit-content;
    }

    /* .company-logos {
        display: none;
    } */

    /* .data {
        width: 60%;
    } */

    .header__data {
        justify-content: space-between;
    }

    .data_box {
        margin-left: 0;
        margin-right: 0;
    }

    .data__boxNumber {
        margin: 0;
        letter-spacing: 0.1rem;
        font-weight: 400;
        /* font-size: 11.5482px; */
        line-height: 15px;
        font-size: 14px;
    }

    .data__boxTitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 16px;
    }
}

@media only screen and (min-width:971px) and (max-width:1250px) {
    .cartoon-img img {
        height: 570px;
        margin-top: 100px;
    }

    .data__boxNumber {
        margin: 0;
        letter-spacing: 0.1rem;

    }
}

/* @media only screen and (min-width:321) and (max-width:576px) {
    .header-banner {
        height: 380px;
    }

    .header__navbar {
        display: flex;
        margin: 0rem 1.5rem;
    }

    .header__navbarLogo {
        font-size: 22px;
    }

    .header__navbar .header__navbarButton {
        display: none;
    }


    .header__navbar img {
        display: block;
        margin-top: 20px;
        margin-left: auto;
        height: 28px;
        width: 28px;
    }

    .header__heading {
        margin: 1rem 0;
        margin-bottom: 3rem;
    }


    .header__heading1,
    .header__heading2 {
        font-size: 25px;
        font-weight: 400;
        line-height: 31.5px;
        margin-left: 8px;
    }

    .custom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .custom img {
        display: block;
        width: 150px;
        margin-top: 7px;
    }

    .radio-name {
        display: flex;
        margin-left: 10px;
    }

    .header_jobtype {
        margin: 0;
    }

    .cartoon-img {
        display: none;
    }

    .helper__text {
        margin-left: 0;

    }

    ::placeholder {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #2D2D2D;
    }

    .radio-part {
        display: flex;
    }

    .search1 {
        display: none;
    }

    .search2 {
        display: block;
    }

    .header__search {
        margin: 0 0.5rem;
        margin-top: 1rem;
        width: fit-content;
    }

    .company-logos {
        display: none;
    }

    .data {
        width: 60%;
    }

    .header__data {
        justify-content: space-between;
    }

    .data_box {
        margin-left: 0;
        margin-right: 0;
    }

    .data__boxNumber {
        margin: 0;
        letter-spacing: 0.1rem;
        font-weight: 400;
        font-size: 11.5482px;
        line-height: 15px;
        font-size: 14px;
    }

    .data__boxTitle {
        font-weight: 500;
        font-size: 12.4466px;
        line-height: 16px;
    }
} */

/* @media screen and (min-width:480px) and (max-width:576px) {
    .header__navbar .header__navbarButton {
        display: none;
    }

    .header__navbar img {
        display: block;
    }

    .header__heading {
        margin-left: 0;
    }

    .header__heading1,
    .header__heading2 {
        font-size: 40px;
        line-height: normal;
        margin-left: 8px;
    }

    .custom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .custom img {
        display: block;
        width: 230px;
    }

    .radio-name {
        display: flex;
        margin-left: 10px;
    }

    .header_jobtype {
        margin: 0;
    }

    .cartoon-img {
        display: none;
    }

    ::placeholder {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #2D2D2D;
    }

    .header__search {
        margin: 0 0.5rem;
        width: fit-content;

    }

    .company-logos {
        margin-top: 10px;
        margin-left: 0.8rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        align-items: center;
        row-gap: 30px;
    }

    .company-logos img {
        width: 100px;
        padding-right: 0.3rem;
        opacity: 0.5;
    }

    .data {
        width: 60%;
    }

    .header__data {
        justify-content: space-between;
    }

    .data_box {
        margin-left: 0;
        margin-right: 0;
    }

    .data__boxNumber {
        font-weight: 500;
        font-size: 18px;
    }

    .data__boxTitle {
        font-weight: 500;
        font-size: 18px;
    }
} */




/* Categories Section */

.categories {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.categories__heading {
    text-align: center;
    margin-top: 4rem;
}

.categories__heading h1 {
    margin-bottom: 0rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
}

.categories__heading h3 {
    margin-top: .5rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 200;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    color: #000000;
}

.categories__skills {
    display: grid;
    /* justify-content: center; */
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 2rem 3rem;
}

.skills_details {
    background: #F4F1DE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* margin: 0rem 1rem 0rem 1rem; */
    /* padding: 0rem 3rem 1rem 3rem; */
    padding: 10px 0;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.categories__skills :hover {
    background-color: #1A85C2;
    color: rgb(224, 224, 224);
}

.categories__skills :hover .details__counter {
    display: block;
}

.skills_details>p {
    margin-top: .5rem;
    margin-bottom: 0rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
}

.skills_details>div {
    display: flex;
    margin-top: 1rem;
    justify-content: center;
}

.skills_details>div>p {
    margin-left: 0.5rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
}

.details__counter {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.5rem;
    margin-left: 1rem;
    display: none;
}

@media only screen and (max-width:767px) {
    .categories__heading h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
    }

    .categories__heading h3 {
        font-weight: 200;
        font-size: 10px;
        line-height: 13px;
    }

    .categories__skills {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        margin: 2rem 1.5rem;
    }

    .skills_details {
        padding-right: 0;
        padding-left: 0;
    }

    .skills_details>p {
        /* margin-top: .5rem;
        margin-bottom: 0rem;
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 600; */

        font-weight: 600;
        font-size: 12.3634px;
        line-height: 16px;
    }

    .skills_details>div>p {
        margin-left: 0;
        font-weight: 200;
        font-size: 13.4703px;
        line-height: 17px;

        /* margin-top: auto;
        margin-bottom: auto; */
    }

    .skills_details img {
        height: 20px;
    }
}

/* @media only screen and (min-width:322px) and (max-width:576px) {
    .categories__skills {

        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        margin: 2rem 1.5rem;
    }

    .skills_details {
        padding-right: 5px;
        padding-left: 5px;
    }
} */



/* featured section */
.featured {
    font-family: "outfit";
    margin-top: 5rem;
    padding: 0.5rem 2rem;
    background: rgb(169, 169, 246, 0.1);
}

.featured__heading {
    text-align: start;
    margin-top: 4rem;
}

.featured__heading h1 {
    font-size: 3rem;
    margin-bottom: 0rem;
}

.featured__heading h3 {
    margin-top: .5rem;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;

}

.featured__job {
    margin: 2rem 0rem;
}

.featured__jobImage {
    position: relative;
    height: 30px;
    top: 45px;
    z-index: 2;
    left: -5px;
}

.featured__jobDetails {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.jobDetails__logo {
    height: 75px;
    width: 75px;
    object-fit: contain;
    align-items: center;
    /* margin-top: auto;
    margin-bottom: auto; */

}

.jobDetails__info {
    margin-left: 1.5rem;
    flex: 1;
}

.info__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 31.3043px;
    line-height: 39px;
}

.info__companyname {
    margin-top: 0;
    margin-bottom: 0.3rem;
    font-weight: 300;
    font-size: 25px;
    line-height: 32px;
}

.info__details {
    font-weight: 200;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 1rem;
}

.jobDetails__main {
    margin-right: 1rem;
    font-weight: 200;
    font-size: 20px;
    line-height: 168.5%;
    color: #444444;
}

/* .jobDetails__main p {
    align-self: flex-end;
} */

.featured__tabs div>div {
    justify-content: space-between;
    /* justify-content: space-evenly; */
}

.featured__tabpanel div p>div {
    box-shadow: none;
}

.featured__button {
    border-radius: 0.8rem;
    border: none;
    font-weight: 600;
    background-color: #1A85C2;
    color: white !important;
    margin-left: 2.2rem;
    margin-bottom: 1rem;
    padding:0.5rem 1rem;
    cursor: pointer;  
}
.featured__button:hover{
    background-color: #0d486a;

}

.featured2__heading {
    text-align: center;
    margin-top: 2rem;
}

.featured2__heading h1 {
    margin-bottom: 0rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
}

.featured2__heading h3 {
    margin-top: .5rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 200;
    font-size: 25px;
}

.arrowIcon {
    display: flex;
    align-items: center;
}

@media only screen and (max-width:767px) {
    .featured {
        padding: 0.3rem 0;
    }

    .featured2__heading h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
    }

    .featured2__heading h3 {
        margin-top: .5rem;
        font-weight: 200;
        font-size: 10px;
        line-height: 13px;
    }


    .featured__jobDetails img {
        width: 46px;
        margin-right: 5px;
        /* object-fit: contain */
    }


    .featured__jobDetails {
        display: flex;
        justify-content: space-between;
        /* width: fit-content; */
    }

    .jobDetails__info {
        margin-left: 0;
        /* flex: 1; */
    }

    .info__title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }

    .info__companyname {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
    }

    .info__details {
        margin-bottom: 1rem;
        font-weight: 200;
        font-size: 12px;
        line-height: 15px;
    }

    .featured2__heading h1 {
        font-weight: 600;
        font-size: 24px;
    }

    .featured2__heading h3 {
        font-weight: 200;
        font-size: 14px;
    }

    /* .jobDetails__main {
        margin-right: 0;
        font-weight: 200;
        font-size: 14px;
        line-height: 20px;
        color: #444444;
    } */
    .jobDetails__main {
        margin-right: 0;
        font-weight: 200;
        font-size: 10px;
        line-height: 168.5%;
    }

}

/* @media only screen and (min-width:322px) and (max-width:576px) {
    .featured {
        padding: 0.3rem 13px;
    }

    .info__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
    }
} */

/* Info Section */
.info {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 2rem 3rem;
    margin-top: 4rem;
    display: flex;

}

.info-1 {
    background-image: linear-gradient(120deg, #ffb703, #fff);
}

.info-2 {
    background-image: linear-gradient(120deg, #fff, #ffb703);
}

.info__images {
    /* overflow: hidden; */
    width:100%;
    margin: 0rem 0rem 0rem 1rem;
    /* margin: auto; */

}

.info__images img {
    height: 480px;
    /* margin-left: auto; */
    border-radius: 0.7rem;
}

.info__content {
    width: 50%;
    /* background-color: red; */
    /* margin-left: 4rem; */
    /* margin-left: auto; */
    margin-top: 1rem;
}


.info__content h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    color: #023047;
    font-size: 60px;
    margin-bottom: 0;
}

.info__content p {
    margin-top: 0.3rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 200;
    font-size: 25px;
    color: #023047;
    line-height: 32px;
}

.content__button {
    background: #023047;
    border-radius: 19.7971px;
    color: white !important;
    padding: "0.3rem 1rem" !important;
}

.getStartedButton {
    background: #023047;
    border-radius: 19.7971px;
    color: white ;
    font-weight: 600;
    cursor: pointer;
    border: none;
    padding: 0.8rem 1rem;
}
.getStartedButton:hover {
    background: #02293f;
}

@media only screen and (max-width:767px) {
    .info {
        display: block;
        padding: 2rem 1rem;
    }

    .info__images {
    overflow: hidden;
        
        margin: auto;
    }

    .info__content {
        width: fit-content;
        margin-left: 0;

    }

    .info__images img {
    /* overflow: hidden; */

        height: 250px;

    }

    .info__content h2 {
        font-weight: 400;
        font-size: 25px;
        line-height: 32px;
    }

    .info__content p {
        font-weight: 200;
        font-size: 16px;
        line-height: 20px;
    }

    .content__button {
        margin-left: 6rem;
    }

    .content__button2 {
        margin-bottom: 8px;
    }
}

@media only screen and (min-width:768px) and (max-width:970px) {
    .info {
        /* display: block; */
        padding: 2rem 2rem;
    }

    .info__images {
    overflow: hidden;
    margin: auto;

        /* margin: 0.2rem 0.3rem; */
    }

    .info__content {
        width: fit-content;
        margin-left: 0;
    }

    .info__images img {
        height: 270px;
        overflow: hidden;
    }

    .info__content h2 {
        font-weight: 500;
        font-size: 35px;
        line-height: 34px;
    }

    .info__content p {
        margin-top: 15px;
        font-weight: 200;
        font-size: 28px;
        line-height: 30px;
    }

    .content__button {
        margin-left: 6rem;
    }

    .content__button2 {
        margin-bottom: 8px;
    }
}

/* @media only screen and (min-width:330px) and (max-width:450px) {
    .info {
        display: block;
        padding: 2rem 1.5rem;
    }

    .info__images {
        margin: 0;
    }

    .info__content {
        width: fit-content;
        margin-left: 0;
    }

    .info__images img {
        height: 270px;
    }

    .info__content h2 {
        font-weight: 500;
        font-size: 35px;
    }

    .content__button {
        margin-left: 7rem;
    }
} */

/* @media only screen and (min-width:451px) and (max-width:576px) {
    .info {
        display: block;
        padding: 2rem 2rem;
    }

    .info__images {
        margin: 0;
    }

    .info__content {
        width: fit-content;
    }

    .info__images img {
        height: 350px;
    }

    .info__content h2 {
        font-weight: 500;
        font-size: 45px;
    }

    .content__button {
        margin-left: 7rem;
    }
} */

/* Clients Section */

.client {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 2rem 158px;
}

.client__heading {
    text-align: center;
    margin-top: 4rem;
}

.client__heading h1 {
    margin-bottom: 0rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 57px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #252B42;
}

.client__heading h3 {
    margin-top: .5rem;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2px;

    /* second-text-color */
    color: #737373;
}

.client__info {
    display: flex;
}

.client__infoDetails {
    display: flex;
    margin-right: 3rem;
}

.client__infoDetails img {
    margin: 1rem;
}

.client__infoDetails div>h2 {
    margin-bottom: 0;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    letter-spacing: 0.1px;

    /* text-color */

    color: #252B42;
}

.client__infoDetails div>h3 {
    margin-top: 0;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* second-text-color */

    color: #737373;
}

.client__infoDetails div>p {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    letter-spacing: 0.2px;

    /* second-text-color */

    color: #737373;
}

@media only screen and (max-width:767px) {
    .client {
        margin: 2rem 8px;
    }

    .client__heading h1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.111735px;
    }

    .client__heading h3 {
        font-weight: 500;
        font-size: 10px;
        line-height: 17px;
        letter-spacing: 0.111735px;
    }

    .client__infoDetails {
        margin-right: 0;
    }

    .client__infoDetails img {
        margin: 0.3rem;
        height: 270px;
        width: 120px;
    }

    .review-2 {
        display: none;
    }

    .client__infoDetails div>h2 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.1px;
    }

}

/* @media only screen and (min-width:340px) and (max-width:576px) {
    .client {
        margin: 2rem 12px;
    }

    .client__infoDetails {
        margin-right: 0;
    }

    .review-2 {
        display: none;
    }
} */



/* // bottom section */
/* .container {
    position: relative;
    font-family: Arial;
} */

.text-block {
    position: absolute;
    top: 20px;
    left: 300px;
    color: white;
    padding-left: 20px;
    padding-right: 40px;
    text-align: center;
}

.boxcolor {
    color: rgb(218, 221, 33);
}

.talentglass {
    font-size: 80px;
}

.secretsoup {
    font-size: 50px;
}

.getaglimpse {
    font-size: 30px;
    font-family: outfit;
}

.block2 {
    bottom: 0px;
    color: white;
    /* padding-left: 20px; */
    /* padding-right: 40px; */
    text-align: center;
    /* width: 100%; */
    /* height: 'auto'; */
    /* margin-top: 10px; */
}

.img1 {
    height: 20rem;
    width: 20rem;
    float: left;
    margin: auto;
    /* margin-left: 150px; */
}

.img2 {
    height: 20rem;
    width: 20rem;
    float: left;
    margin: auto;
}

.blocktext1 {
    padding-top: 120px;
    /* margin-top:120px; */ 
     /* width: 50%; */
    /* float: left;
    
    text-align: left;
    margin-left: 100px;
    margin-top: 130px; */
}
.btext1{
    margin-top: 100px;
}

.blocktext2 {
    /* width: 50%;
    float: left;
    text-align: left;
    margin-top: 130px;
    margin-left: 150px; */
}

.blocktext3 {
    /* width: 50%; */
    /* float: left; */
    /* text-align: left; */
    /* margin-left: 100px; */
    /* margin-top: 50px; */
    /* padding-top: 50px; */
}

.btext1 {
    /* color: black; */
    font-size: 40px;
    margin: 0%;
    font-weight: 400;
    color: #023047;
    font-family: outfit, sans-serif;
}

.toknowmore {
    font-size: 50px;
    text-align: center;
}

.button {
    background-color: rgb(218, 221, 33);
    height: 40px;
    width: 130px;
    margin-left: 45%;
    margin-bottom: 35px;
}

.footer {
    width: 100%;
    height: 100px;
    background-color: rgb(5, 63, 253);
}

.footerdiv1 {
    float: left;
    width: 50%;
}

.footerdiv2 {
    float: left;
    width: 50%;
}

.talentbox {
    margin-left: 80px;
    color: aliceblue;
    font-size: 40px;
}

