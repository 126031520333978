@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;900&display=swap');
/* Navbar styling */

/*  */

.rp__profile {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
}

.rp__saveChanges {
    background: #000000;
    border-radius: 10px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #FFFFFF;
    text-align: center;
    margin-left: auto;
    height: 50%;
    padding: .5rem 1.3rem;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}

.rp__details {
    display: flex;
}

.recruiterprofile__leftsection {
    width: 40%;
    margin-right: 1.5rem;
}

.recruiterprofile__rightsection {
    width: 100%;
}

.ls__box {
    background: #FFFFFF;
    box-shadow: 0px 0px 5.33333px rgba(0, 0, 0, 0.25);
    border-radius: 23.0788px;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
}

.ls__boxheading {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    margin-left: 36px;
    color: #25364F;
    margin-bottom: 0px;
}

.ls__avatar {
    zoom: 350%;
    margin-left: auto;
    margin-right: auto;
}

.ls__boxName {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 22.5px;
    color: #25364F;
    margin-top: 0.5rem;
    text-align: center;
    margin-bottom: 0;
}

.rs__heading {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 21.3005px;
}

.rs__desc {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 21.3005px;
    border-bottom: 1px solid #000000;
}

.rs__descDetails {
    font-family: 'outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 16.4982px;
    line-height: 25px;
}

.rs__heading1 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 21.3005px;
    margin-bottom: 0.5rem !important;
}

.rs__headingDetails {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 23.4306px;
    /* border-bottom: 1.06503px solid #000000; */
    margin-top: 0;
    padding-bottom: 0.2rem;
}


.css-ha3bif-MuiTimelineItem-root:before {
    content: none !important;
}

.postjob__heading {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: #023047;
    margin: 0;
}

.postjob__check {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    margin: 24px 0px;
    margin-bottom: 13px;
    color: #023047;
}

.postjob__buttons {
    border: 1px solid #219EBC;
    border-radius: 10px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 22.3091px;
    color: #219EBC;
    background-color: white;
    cursor: pointer;
    padding: 10px 65px;
}

.postjob__label {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    color: #023047;
}

.postjob__input {
    background: #E0E0E0;
    border-radius: 10px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    margin-bottom: 24px;
    padding: 17px 29px;
    width: 100%;
    border: none;
    outline-offset: 0;
}

input:focus {
    outline: none !important;
}

.postjob__button {
    background: #219EBC;
    border-radius: 10px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 22.3091px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    padding: 15px 220px;
    width: 100%;
    border: none;
    cursor: pointer;
}

.active {
    border-radius: 10px;
    border: 1px solid #219EBC;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 22.3091px;
    color: #FFFFFF;
    background-color: white;
    cursor: pointer;
    padding: 10px 65px;
    background: #219EBC;
}

.postjob__or {
    font-weight: 300;
    font-size: 20px;
    align-items: center;
    text-align: center;

    color: #023047;
}

.postjob__register {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    margin-left: 10px;
    cursor: pointer;
}

.flex__item{
    display: flex;
    /* justify-content: space-between; */
    gap:0.5rem;
}
.flex__col_item{
    display: flex;
    flex-direction: column;
}
.title {
    color: #023047;
}