.renewButton {
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  margin: 0;
  padding: 0.3rem 0.6rem;
  background-color: gray;
  color: white;
  border-radius: 10.4496px;
}

.switchButton {
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  margin: 0;
  padding: 0.3rem 0.6rem;
  background-color: #2bc155;
  color: white;
  border-radius: 10.4496px;
}
.greenText {
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  margin: 0;
  padding: 0.3rem 0.6rem;
  color: #2bc155;
  border-radius: 10.4496px;
}
.redText {
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  margin: 0;
  padding: 0.3rem 0.6rem;
  color: #c12b2b;
  border-radius: 10.4496px;
}
