.flex-col{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    font-family: Outfit ,Arial, Helvetica, sans-serif;
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}
.pagination-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #f2f2f2;
    cursor: pointer;
}
.pagination-button:hover {
    background-color: #e6e6e6;
}
.pagination-button:active {
    background-color: #d9d9d9;
}
.pagination-button:focus {
    outline: none;
}
.pagination-button[disabled] {
    background-color: #f2f2f2;
    cursor: not-allowed;
}
.pagination-button[disabled]:hover {
    background-color: #f2f2f2;
}

.pagination-select{
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #f2f2f2;
    cursor: pointer;
}

.pagination-select:focus {
    outline: none;
}
.pagination-select:hover {
    background-color: #e6e6e6;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.table {
  /* create a modern table css */
    /* border-collapse: collapse; */
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    /* font-size: 0.9rem; */
}
.table tr {
    border-bottom: 1px solid #ddd;
}
.table tr:hover {
    background-color: #f5f5f5;
}
.table th {
    padding: 0.5rem;
    text-align: left;
    background-color: #f2f2f2;
}
.table td {
    padding: 0.5rem;
    text-align: left;
}